export default  {
    path: "/forum",
    name: 'forum',
    redirect: "/forum/main",
    meta:{
      title:'社区'
    },
    component: () => import("@/pages/community/Community.vue"),
    children:[
        {
            path: "/forum/main",
            name: 'communitymain',
            meta:{
                title:'社区主页'
            },
            props: route => ({ tag: Number(route.query.tag)||0,state: route.query.state||null }),
            component: () => import("@/pages/community/main/MainRouter.vue"),
        },
        {
            path: "/forum/newpost",
            name: 'newpost',
            meta:{
                title:'社区 - 发布帖子'
            },
            props: route => ({ postId: Number(route.query.id) }),
            component: () => import("@/pages/community/postForm/PostFormRouter.vue"),
        },
        {
            path: "/forum/editpost/:id",
            name: 'editpost',
            meta:{
                title:'社区 - 编辑帖子'
            },
            props:(route)=>{return {tid: route.params.id}},
            component: () => import("@/pages/community/postForm/PostFormRouter.vue"),
        },
        {
            path: "/forum/viewpost/:id",
            name: 'viewpost',
            meta:{
                title:'社区 - 查看帖子'
            },
            props:(route)=>{return {tid: route.params.id}},
            component: () => import("@/pages/community/postView/ViewPostRouter.vue"),
        },
        {
            path: "/forum/personalCenter",
            name: 'personalCenter',
            props:true,
            meta:{
                title:'社区 - 个人中心'
            },
            component: () => import("@/pages/community/personalCenter/personalCenterRouter.vue"),
        },
        {
            path: "/forum/search",
            name: 'about',
            props:true,
            meta:{
                title:'社区 - 搜索'
            },
            component: () => import("@/pages/community/search/index.vue"),
        },
        {
            path: "/forum/mail",
            name: 'mail',
            props:true,
            meta:{
                title:'社区 - 站内信'
            },
            component: () => import("@/pages/community/mail/index.vue"),
        },
    ],
}
