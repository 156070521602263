const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  render: (state) => state.app.render,
  is_pro_env: (state) => state.app.is_pro_env,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  qrcodeUrl: (state) => state.user.qrcodeUrl,
  cookie: (state) => state.user.cookie,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name.slice(0, 8),
  username: (state) => state.user.username,
  uid: (state) => state.user.uid,
  enName: (state) => state.user.enName,
  QQ: (state) => state.user.QQ,
  ID: (state) => state.user.ID,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  role: (state) => state.user.role,
  auth: (state) => state.user.auth,
  userStat: (state) => state.user.userStat,
  email: (state) => state.user.email,
  roleName: (state) => state.user.roleName,
  companys: (state) => state.user.companys,
  products: (state) => state.user.products,
  allProducts: (state) => state.user.allProducts,
  accountInfo: (state) => state.user.accountInfo,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  needBindRtx: (state) => state.user.needBindRtx,
  isAdmin: (state) => state.user.isAdmin,
  logined: (state) => state.user.logined,
  options: (state) => state.user.options,
  limitTime: (state) => state.user.limitTime,
  project_id: (state) => state.project.project_id,
  project: (state) => state.project.project,
  reachBottom: (state) => state.app.reachBottom,
  unreadNotifyCount:(state)=>state.user.unreadNotifyCount,
  hiddenTag:(state)=>state.app.hiddenTag
};

export default getters;
