/*
 * @Author       : lenchen
 * @Date         : 2020-06-23 11:29:03
 * @LastEditors  : lenchen
 * @LastEditTime : 2021-02-02 14:11:10
 * @FilePath     : \ihub\src\utils\request.js
 * @Description  :
 */
import axios from "axios";
import { Message } from "element-ui";
import md5 from "blueimp-md5";
import { getToken } from "@/utils/auth";
import store from "@/store";
import router from "@/router";
import siteConfig from "@/config";
import ab2str from "arraybuffer-to-string";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: siteConfig.API.AXIOS_TIMEOUT,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const ts = Math.round(new Date().getTime() / 1000);
    const sign = md5(`${ts}BOiZe8yxyseVIHif`);
    if (!config.data) {
      config.data = {};
    }
    config.headers.sign = sign;
    config.headers.timestamp = ts;
    config.headers.ticket = getToken();
    config;
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  async (response) => {
    if (
      response.config.url.indexOf("/upload.php?c=download&a=downloadFile") > -1
    ) {
      if (response.headers["content-type"] === "application/octet-stream") {
        return response;
      }
      const resStr = ab2str(response.data);
      return Promise.reject(JSON.parse(resStr));
    }

    const res = response.data;
    if (siteConfig.API.ERROR_CODE.includes(res.ret)) {
      await store.dispatch("user/logout");
      // router.push(`/home?login=1&redirect=${router.currentRoute.fullPath}`);
    }

    handleErrorCode(res);

    if (
      siteConfig.API.SUCCESS_CODE.includes(res.ret || res.code) ||
      siteConfig.API.NO_MODIFY_AUTH_CODE.includes(res.ret)
    ) {
      return res;
    }

    return res;
  },
  async (error) => {
    console.log('error',error);
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
      offset: 50,
    });
    return Promise.reject(error);
  }
);

const handleErrorCode = async (res)=>{
  if ([-102,-116,-117,-118].includes(res?.code)) { // -102封号 -116 账号异常，冻结或删除或其它-117 未完成邀请操作-118 未签署保密协议
    if(res?.code === -102 && window.location.hash.includes('oauth')) return; // 登录页不需要
    Message({
      message: res.message,
      type: "error",
      duration: 5 * 1000,
      offset: 50,
    });
    await store.dispatch("user/logout");
    store.state.user.token = getToken();
    store.state.app.render = !store.state.app.render;
    if([-116,-117,-118].includes(res?.code)){
      window.location.href = `/#/oauth?app_id=5012568a421eeb0b&redirect_url=${location.origin}/#${router.currentRoute.fullPath}`
    }
  }
}

export default service;
