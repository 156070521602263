import request from "@/utils/request";

// 【需登录】用户信息
export function getAccountInfo(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=member&ac=info",
        method: "get",
        params,
    });
}

export function getHeadernav(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=common&ac=headernav",
        method: "get",
        params,
    });
}

export function getMemberByUser(data) {
    return request({
      url: "/index.php?c=webTask&a=getMembByUser&type=json",
      method: "post",
      data,
    });
  }
  
  export function login(data) {
    return request({
      url: "/api?c=user&a=loginByCode",
      method: "post",
      data,
    });
  }

  export function loginByToken(data) {
    return request({
      url: "/api?c=user&a=loginByToken",
      method: "post",
      data,
    });
  }

  export function getInstallerInfo(data) {
    return request({
      url: "/api?c=file&a=getInstallerInfo",
      method: "post",
      data,
    });
  }
  
  export function sendVerifyEmail(data) {
    return request({
      url: "/api?c=user&a=sendVerifyEmail",
      method: "post",
      data,
    });
  }
  
  export function verifyEmail(data) {
    return request({
      url: "/api?c=user&a=verifyEmail",
      method: "post",
      data,
    });
  }
  
  export function getUserAuth(data) {
    return request({
      url: "/api?c=user&a=getUserAuth",
      method: "post",
      data,
    });
  }
  
  export function sendRegisterApply(data) {
    return request({
      url: "/api?c=user&a=sendRegisterApply",
      method: "post",
      data,
    });
  }

  export function inviteUser(data) {
    return request({
      url: "/api?c=user&a=inviteUser",
      method: "post",
      data,
    });
  }

  export function getConstractStatus(data) {
    return request({
      url: "/api?c=user&a=getConstractStatus",
      method: "post",
      data,
    });
  }

  export function modifyUserInfo(data) {
    return request({
      url: "/api?c=user&a=modifyUserInfo",
      method: "post",
      data,
    });
  }
  
  export function oauthLogin(data) {
    return request({
      url: "/index.php?c=oauthLogin&a=userLogin&type=json",
      method: "post",
      data,
    });
  }
  
  export function register(data) {
    return request({
      url: "/index.php?c=PassLogin&a=Register&type=json",
      method: "post",
      data,
    });
  }
  
  export function bindWx(data) {
    return request({
      url: "/index.php?c=PassLogin&a=BindWx&type=json",
      method: "post",
      data,
    });
  }

  export function getAllCount() {
    return request({
        url: "/community/custom.php?module=forum&mod=message&ac=notifyBox",
        method: "get",
    });
  }

  export function getEnv() {
    return request({
        url: "/community/custom.php?module=doc&mod=common&ac=env",
        method: "get",
    });
  }

  // 发送心跳
  export function heartbeat(data) {
    return request({
        url: "/community/custom.php?module=member&mod=member&ac=heartbeat",
        method: "post",
        data
    });
  }
  
// 轮询检测当前用户状态
/* 
 *-10004为被封禁状态
*/
export function checkUserStatus() {
  return request({
    url: '/api?c=user&a=checkUserStat',
    mehtod: 'post'
  })
}

// 检测赛事提交日期是否到期
export function getCountDown(){
  return request({
    url: '/community/custom.php?module=doc&mod=common&ac=env',
    method: 'get'
  })
}

export function  getUserDetail(params){
  return request({
    url:'community/custom.php?module=member&mod=member&ac=profile',   
    method: "get",
    params
  })
}