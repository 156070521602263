<template>
    <svg
      className="svg-icon"
      aria-hidden="true"
      :style="{ width: width, height: height }"
    >
      <use :xlink:href="iconName" />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      iconClass: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "16px",
      },
      height: {
        type: String,
        default: "16px",
      },
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`;
      },
    },
  };
  </script>

  
<style scoped>
/*此处为所有图标默认显示样式*/
.svg-icon {
  fill: currentColor;
  overflow: hidden;
}
</style>
  